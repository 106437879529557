@import '__importable.scss';
.container {
	z-index: 0;
	display: grid;
	gap: 0.8rem;
	position: relative;
	max-width: $max-width;
	margin: auto;
}

.base {
	@extend .container;
	grid-template-columns: 1fr;
	place-items: center;
	gap: 0.8rem;
	padding: 0.8rem;
	margin: auto;
	overflow-x: unset;

	@media screen and (min-width: $media-md-max) {
		grid-template-columns: repeat(2, 1fr);
		padding: 1.6rem;
	}

	@media screen and (min-width: $media-xl) {
		grid-template-columns: repeat(3, 1fr);
		padding: 2.4rem 3.2rem;
	}

	@media screen and (min-width: $media-xxl) {
		grid-template-columns: repeat(4, 1fr);
		padding: 3.2rem 6.4rem;
	}

	@media screen and (min-width: $max-width) {
		padding: 3.2rem 12.8rem;
	}
}

.tops {
	@extend .container;
	gap: 0.8rem;
	overflow-x: hidden;
	grid-template-columns: repeat(2, 1fr);

	@include product-grid-padding($removeInlineMobilePadding: true);

	@media screen and (width >= $media-sm) {
		>[class*='Heading'] {
			padding-inline: 1.6rem;
		}
	}

	@media screen and (width >= $media-md) {
		grid-template-columns: repeat(3, 1fr);

		>[class*='Heading'] {
			padding-inline: 0;
		}
	}

	@media screen and (width >= $media-xl) {
		grid-template-columns: repeat(4, 1fr);
	}

	&--singleCol {
		@extend .container;
		@extend .tops;

		grid-template-columns: 1fr;

		@media screen and (width >= $media-sm) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media screen and (width >= $media-md) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media screen and (width >= $media-xl) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

.all-tops {
	@extend .container;
	gap: 0.8rem;
	padding: 0.8rem 0 3.2rem;
	grid-template-columns: repeat(2, 1fr);
	width: 100%;
	
	@media screen and (width >= $media-md) {
		grid-template-columns: repeat(3, 1fr);
		padding: 1.6rem;
	}

	@media screen and (width >= $media-xl) {
		grid-template-columns: repeat(4, 1fr);
	}
}

.accessory {
	@extend .container;
	grid-template-columns: repeat(1, 1fr);
	padding: 1.6rem 0;

	@media screen and (min-width: $media-md-max) {
		grid-template-columns: repeat(3, 1fr);
		padding: 3.2rem 0;
	}

	@media screen and (min-width: $media-xl) {
		grid-template-columns: repeat(4, 1fr);
	}
}

.build-flow {
	@extend .container;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.8rem;
	padding-bottom: 0.8rem;

	@media screen and (min-width: $media-md-max) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media screen and (min-width: $media-md-lg) {
		grid-template-columns: repeat(2, 1fr);
		padding: 0.8rem 0;
		&--withSubscription-fullWidth {
			grid-template-columns: repeat(6, 1fr);
		}
	}

	@media screen and (min-width: $media-xl) {
		grid-template-columns: repeat(3, 1fr);
		&--withSubscription-fullWidth {
			grid-template-columns: repeat(7, 1fr);
		}
	}

	@media screen and (min-width: $media-xxl) {
		grid-template-columns: repeat(4, 1fr);
		&--withSubscription-fullWidth {
			grid-template-columns: repeat(8, 1fr);
		}
	}
}
