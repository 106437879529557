@import '__importable.scss';
.option {
	@include hoverable;
	display: flex;
	align-items: center;
	padding: 1.4rem 0.8rem 1.3rem;
	gap: 0.4rem;
	border-color: $gray-2;

	img {
		width: clamp(4.8rem, 30%, 7.2rem);
		height: auto;

		// Fixes Safari "Octagon" Shape
		// Semi-relevant context around why the issue occurs here: https://stackoverflow.com/questions/17202128/rounded-cornes-border-radius-safari-issue
		clip-path: none !important;
	}

	@media screen and (min-width: $media-md) {
		gap: 0.8rem;
		padding: 0.8rem 1.6rem;
		height: 5.2rem;
		max-height: 5.2rem;
	}
}

.unavailableOption {
	@extend .option;
	pointer-events: none !important;
	cursor: not-allowed;
	opacity: 0.4;

	svg path {
		fill: $gray-4;
	}
}

.selected {
	@extend .unavailableOption;
	background-color: $gray-2;
	border-color: $blue-shade;
	opacity: unset;
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.6rem;
	margin-top: 1.6rem;
}

.column {
	display: flex;
	flex-direction: column;
	span {
		font-size: 1.2rem;
	}
}

.content {
	padding: 1.6rem;
	height: calc(100% - $site-header-height);
	max-height: calc(100% - $site-header-height);
	overflow: initial !important;

	@media screen and (min-width: $media-md) {
		height: fit-content;
		max-height: 75vh;
		overflow: hidden !important;
	}
}

.tabs{
	height: inherit;

	.tab-content {
		height: 100%;
		overflow-y: auto;

		@media screen and (min-width: $media-md) {
			height: fit-content;
			max-height: 65vh;
			overflow: auto !important;
			padding: 0 1rem 2.6rem 0;
			scrollbar-width: thin;
		}
	}
}

.frameButton {
	width: 100%;

	span{
		justify-content: space-between !important;
	}

	@media screen and (min-width: $media-md-max) {
		width: auto;
	}
}
