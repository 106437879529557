@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
}

.row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 1.6rem;
	cursor: pointer;

	& > h6 {
		margin-bottom: 0;
	}

	&:hover {
		background-color: $gray-1;
	}

	&:active {
		background-color: $gray-2;
	}
}

.header {
	border-bottom: 1px solid $gray-3;
}

.subtitle {
	padding: 0 1.6rem 1.6rem;
	color: $gray-4;
}
