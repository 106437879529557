@import '__importable.scss';
.tabs-list {
	list-style-type: none;
	white-space: nowrap;
	overflow-x: auto;
	padding-left: 0;
	gap: 1.6rem;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: $media-lg) {
		display: flex;
		padding-left: 0;
		margin-right: 0;
		overflow-x: unset;
	}

	@media screen and (min-width: $media-xl) {
		margin-right: 0;
	}
}

.tabs-trigger {
	display: inline-block;
	position: relative;
	line-height: 3.2rem;
	font-size: 1.4rem;
	padding: 0.9rem 0.8rem 1rem;
	background-color: $gray-0;
	border-radius: $radius-2 $radius-2 0 0;
	color: $gray-3;
	cursor: pointer;


	&:last-child {
		margin-right: 3.2rem;
	}

	&[data-state='active'] {
		color: $gray-5;

		&::after {
			background-color: $blue-3;
			transition: background-color $transition-3;
		}
	}

	&::before,
	&::after {
		content: '';
		background-color: $gray-0;
		position: absolute;
		left: 0;
		width: 100%;
		height: 4px;
		z-index: 1;
	}

	&::before {
		top: 0;
	}

	&::after {
		bottom: 0;
	}

	&:hover {
		&::before,
		&::after {
			transition: 0s;
		}

		&:not([data-state='active']) {
			border-radius: $radius-2;

			&::after {
				background-color: $gray-2;
			}
		}

		&[data-state='active']::after {
			background-color: $blue-3;
		}
	}

	&:focus-visible {
		span {
			box-shadow: $shadow-pink;
			border-radius: $radius-2;
		}
	}

	@media screen and (min-width: $media-lg) {
		display: flex;
		align-items: center;

		&:hover {
			color: $blue-1;
			background-color: $gray-2;

			&::before,
			&::after {
				background-color: $gray-2;
				transition: 0s;
			}
		}
	}

}

.tabs-content {
	padding: 1.6rem 1.6rem 1.6rem 2.4rem;
	margin-top: 1.6rem;
	background-color: $gray-2;
	border-radius: $radius-4;

	@media screen and (min-width: $media-lg) {
		height: 100%;
	}
	@media screen and (min-width: $media-xl) {
		max-width: unset;
	}
}
