@import '__importable.scss';
.subscriptionButton {
	span {
		justify-content: space-around !important;

		div:first-child {
			margin-inline: auto;
			@media screen and (max-width: $media-sm-max) {
				font-size: 1.4rem;
			}
		}
	}
}
