@import '__importable.scss';
.container {
	padding: 1.6rem;
	gap: 0.4rem;
	border-radius: 1.6rem;
	background-color: $gray-1;
}

.buttons{
	padding-top: 1rem;
	gap: 0.8rem;

	&--with-tag{
		gap: 1.6rem;
	}
	
	@media screen and (min-width: $media-lg){
		&--with-tag{
			gap: 3.2rem;
		}
	}

	.button{
		position: relative;
		width: 100%;

		.lozenge{
			position: absolute;
			top: -1rem;
			right: -1rem;
		}

		.selected {
			border: 1px solid $blue-2;
			background-color: $gray-2;
		}

		.gray {
			border: 1px solid $gray-3;
			color: $gray-3;
		}
	}
}