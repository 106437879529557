@import '__importable.scss';
.container {
	padding-top: 1.6rem;
	gap: 1.6rem;
	
	
	@media screen and (min-width: $media-lg){
    	padding: 1.6rem 1.6rem 1.6rem 12rem;
	}

	.title{
		padding: 1.4rem 1.6rem 0.8rem 1.6rem;
		gap: 1rem;
		span{
			font-size: 1.4rem !important;
			text-align: justify;
		}
	}

	.bundle{
		border: 0.1rem solid $gray-2;
		padding: 1.6rem;
		gap: 1.6rem;
		border-radius: 1.6rem;
		background-color: $white;

		.shape{
			padding: 1.6rem;
			gap: 0.4rem;
			border-radius: 1.6rem;
			background-color: $gray-1;

			span{
				justify-content: left;
			}
		}

		.top-title{
			padding-top: 0.8rem;
		}
		.cards{
			margin: initial;
		}
	}
}

.sidebar{
	min-height: 0;
	display: flex;
	flex-direction: column;

	
	@media screen and (min-width: $media-lg){
		margin: 1.6rem 2rem 0 0;
		min-height: 70vh;
	}
	@media screen and (min-width: $media-xxl){
		margin: 1.6rem 7rem 0 0;
		min-height: 70vh;
	}

	.cta{
		margin-top: -3rem;
		background-color: $white;
	}
}

.myTops{
	background-color: $gray-1;
	display: flex;
	flex-direction: column;
	border-radius: 1.6rem;
}

.copy {
	height: 5.6rem;
	border-top-right-radius: 1.6rem;
	border-top-left-radius: 1.6rem;
	border-bottom: 0.1rem solid $gray-2;
	text-align: center;
	display: flex;
	align-items: center;

	span{
		width: 100%;
		font-size: 1.4rem !important;
		vertical-align: middle;
	}

	@media screen and (min-width: $media-md-lg){
		background-color: $white;
		border: 0.1rem solid $gray-2;
	}

}
.tops{
	padding: 1.6rem;
	flex-grow: 1;
	@media screen and (min-width: $media-md-lg){
		background-color: $white;
		border-left: 0.1rem solid $gray-2;
		border-right: 0.1rem solid $gray-2;
	}
	p{
		font-weight: 500;
	}
}