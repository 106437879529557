@import '__importable.scss';
.hero {
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-items: start;
	width: 100%;
	min-width: 32rem;
	position: relative;
	color: $gray-5;
	z-index: $zindex-1;
	min-height: 25.6rem;
	height: 51.2rem;

	h1,
	h3 {
		margin: 0;
		text-align: center;

		@media (min-width: $media-md) {
			text-align: left;
		}
	}

	&.banner-medium {
		min-height: auto;
		height: 25.6rem;
	}

	&.banner-small {
		min-height: auto;
		height: 12.8rem;
	}

	&.banner-responsive {
		min-height: auto;
		height: 12.8rem;
		@media screen and (min-width: $media-sm) {
			height: 25.6rem;
		}
	}

	&.default {
		.image-container {
			justify-self: end;
		}

		@media screen and (max-width: $media-xxl) {

			picture,
			img {
				object-position: left center;
			}
		}

		@media screen and (max-width: $media-md) {
			justify-items: center;
			gap: 0;

			.image-container {
				padding-top: 1.6rem;
				height: 100%;
			}

			.content-container {
				align-items: center;
			}

			.button-grouping {
				justify-content: center;
			}
		}
	}

	&.plp {

		.image-container {
			justify-self: end;
		}

		.button-grouping {
			padding-top: 0.8rem;
		}

		@media screen and (max-width: $media-xxl) {

			picture,
			img {
				object-position: left center;
			}
		}
	}

	&.compact {
		height: 25.6rem;

		.image-container {
			justify-self: end;

			picture,
			img {
				object-position: left center;
			}
		}

		.button-grouping {
			padding-top: 0.8rem;
		}

		.content-container {
			max-width: 72rem;
		}

		.tag {
			font-size: 1.4rem;
			letter-spacing: 0.28px;
		}

		h1 {
			font-size: 3.2rem;
			letter-spacing: 0.102px;
		}

		@media screen and (max-width: $media-md-max) {
			h1 {
				font-size: clamp(1.6rem, 4vw, 2rem);
				line-height: clamp(2rem, 4vw, 2.4rem);
				letter-spacing: 0.002em;
			}

		}
	}

	&.plp, &.compact {
		h1, h3 {
			text-align: left;
		}

		@media screen and (max-width: $media-md-max) {
			height: 25.6rem;

			h3 {
				font-size: clamp(1.6rem, 4vw, 2rem);
				line-height: clamp(2rem, 4vw, 2.4rem);
				letter-spacing: 0.002em;
			}

			.subtext,
			.highlight {
				font-size: 1.4rem;
				font-weight: 400;
				line-height: 146%;
			}

			.tag {
				font-size: 1.4rem;
				letter-spacing: 0.28px;
			}

			.image-container {
				width: 100%;

				picture,
				img {
					object-position: left center;
				}
			}

			.content-container {
				max-width: 20rem;
			}
		}
	}

	&.full-bleed {

		@media screen and (max-width: $media-md) {
			justify-items: center;
			justify-content: center;
			align-items: start;

			.button-grouping {
				justify-content: center;
			}

			.content-container {
				align-items: center;
			}

			.subtext {
				text-align: center;
			}

		}
	}

	&.full-bleed-plp {

		@media screen and (max-width: $media-md) {
			height: 25.6rem;
			justify-items: start;
			align-items: start;

			.button-grouping {
				justify-content: start;
			}

			.content-container {
				align-items: start;
			}

			.title {
				display: none;
			}

		}
	}

	&.video {
		align-items: end;

		.image-container {
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 40.73%, rgba(0, 0, 0, 0.70) 100%);
		}

		.content-container {
			padding-bottom: 1.6rem;
			max-width: unset;
			width: 100%;

			.button-grouping {
				padding-top: 0.8rem;

				> * {
					flex-grow: 1;

					> * {
						width: auto !important;
					}
				}
			}
		}

		@media screen and (min-width: $media-md) {
			align-items: center;

			.content-container {
				max-width: 64rem;
				width: initial;

				.button-grouping {
					padding-top: 0.8rem;

					> * {
						flex-grow: initial;

						> * {
							width: initial;
						}
					}
				}
			}

			.image-container {
				background: initial;
			}
		}

		h1, h3 {
			text-align: left;
		}
	}

	&.big-image {
		height: 100%;
	}

}

.hero-container {
	padding: 0.4rem 1.6rem;

	@media screen and (width >=$media-xl) {
		padding: 0.8rem 3.2rem;
	}

	@media screen and (width >=$media-xxl) {
		padding: 0.8rem 6.4rem;
	}

	@media screen and (width >=$max-width) {
		padding: 0.8rem 12.8rem;
	}
}

.content-container {
	align-items: start;
	color: $gray-5;
	padding: 1.6rem 1.6rem 0 1.6rem;
	height: min-content;
	gap: var(--spacing-spacing-xs, 0.4rem);
	z-index: $zindex-2;
	max-width: 64rem;
	position: absolute;

	&.responsive {
		padding: 1.6rem 0.8rem 0 0.8rem;
	}

	&.center-content {
		max-width: 100%;
		width: 100%;
		align-items: center;
		margin-inline: unset;
		padding: 0 1.6rem;

		h1 {
			max-width: 64rem;
			text-align: center;
			padding: 0 1.6rem;
		}

		.button-grouping {
			justify-content: center;
		}

		@media screen and (max-width: $media-md) {
			height: 100%;
			justify-content: center;
		}
	}

	.subtext,
	.highlight {
		font-weight: 500;
		font-style: normal;
		font-size: 1.8rem;
		line-height: normal;
	}

	.tag {
		font-family: var(--platform);
		font-size: 2rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.04px;
	}

	.cut-paper {
		margin-right: 1ch;
	}

	.button-grouping {
		padding-top: 1.6rem;
		align-self: stretch;
		text-align: center;
		gap: 0.8rem;

		span {
			font-size: 1.4rem;
			font-weight: 500;
		}

		@media screen and (max-width: $media-md) {
			&[data-has-product='true'] {
				.button-contenful {
					display: none;
				}
			}

			.button-hidden {
				display: none;
			}
		}
	}

	@media screen and (min-width: $media-md) {
		margin-inline: clamp(3.2rem, 4vw, 8rem);
	}

	.link {
		margin-block: 1.6rem;
	}
}

.image-container {
	width: 100%;
	position: relative;
	height: inherit;

	picture,
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.children-container {
	width: 100%;
	height: 100%;

	@media screen and (min-width: $media-sm) {
		height: 100%;
	}
}

.logo {
	margin-bottom: 1.6rem;

	@media screen and (width > $media-md) {
		margin-top: -5rem;
		margin-bottom: 5rem;
	}
}
